import { call, fork, put, takeLatest } from 'redux-saga/effects';
import { fetchFormDataRequest, fetchFormDataSuccess } from './slice';
import postData from '@utils/postData';
import { GET_FORM_INSTANCE_DATA } from './queries';
import { catchError } from '@utils/sentry';
export function* fetchStandaloneFormDataSaga({ payload }) {
    var _a;
    try {
        const { id } = payload;
        const queryResponse = (yield call(postData, {
            queryString: GET_FORM_INSTANCE_DATA,
            payload: { id },
            spreadPayload: true,
        }));
        const formTestInstanceResponse = ((_a = queryResponse === null || queryResponse === void 0 ? void 0 : queryResponse.data) === null || _a === void 0 ? void 0 : _a.frm_form_instance[0]) || {};
        yield put(fetchFormDataSuccess(formTestInstanceResponse));
    }
    catch (error) {
        yield call(catchError, { title: 'Standalone forms error', error: error });
    }
}
export function* fetchStandaloneFormWatcherSaga() {
    yield takeLatest(fetchFormDataRequest.type, fetchStandaloneFormDataSaga);
}
export function* standaloneFormRootSaga() {
    yield fork(fetchStandaloneFormWatcherSaga);
}
