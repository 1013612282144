import { createSlice } from '@reduxjs/toolkit';
export const initialState = {
    formData: null,
    loading: true,
    error: null,
};
export const standaloneFormSlice = createSlice({
    name: 'standaloneForms',
    initialState,
    reducers: {
        fetchFormDataRequest(state, _action) {
            state.loading = true;
        },
        fetchFormDataSuccess(state, action) {
            state.loading = false;
            state.formData = action.payload;
        },
    },
});
export const { fetchFormDataRequest, fetchFormDataSuccess } = standaloneFormSlice.actions;
export default standaloneFormSlice.reducer;
