import { jsx as _jsx } from "react/jsx-runtime";
import { createBrowserRouter } from 'react-router-dom';
// Root Layout
import RootErrorPage from './RootErrorPage';
import RootLayout from './RootLayout';
// Auth Components
import { Logout } from '@containers/Auth/Logout';
import { Redirect } from '@containers/Auth/Redirect';
import LoginContainer, { AuthRedirectLoadable } from '@containers/Auth/Loadable';
// Login Components
import Login from '@components/Login/Loadable';
import VerifyOtp from '@components/Login/VerifyOtp/Loadable';
// Feedback Components
import Feedback from '@containers/Feedback/Loadable';
import Thankyou from '@containers/Thankyou/Loadable';
// Assessment Components
import Assessment from '@containers/Assessment/Loadable';
import AssessmentError from '@containers/Assessment/AssessmentError/Loadable';
import AssessmentSubmission from '@containers/Assessment/AssessmentSubmission/Loadable';
import AsyncPlayGround from '@containers/Assessment/AsyncPlayGround/Loadable';
import HardwareTest from '@containers/Assessment/HardwareTest/Loadable';
import Instructions from '@containers/Assessment/Instructions/Loadable';
import Invite from '@containers/Assessment/Invite/Loadable';
import ObjectivePlayGround from '@containers/Assessment/ObjectivePlayGround/Loadable';
import PlayGround from '@containers/Assessment/PlayGround/Loadable';
import Submission from '@containers/Assessment/Submission/Loadable';
// Candidate Registration Components
import RegisterCandidate from '@containers/CandidateRegistration/Loadable';
import RegistrationForm from '@containers/RegistrationForm/Loadable';
// Payment Components
import BookingConfirm from '@containers/Payment/BookingConfirm/Loadable';
import BookingReSchedule from '@containers/Payment/RescheduledBooking/Loadable';
import CancelBookingContainer from '@containers/Payment/CancelBooking/Loadable';
import PaymentLayout from '@containers/Payment/Loadable';
import PaymentSummary from '@containers/Payment/PaymentSummary/Loadable';
import SKUDetails from '@containers/Payment/SKUDetails/Loadable';
// Payment Error Screens
import BookingWindowCancelled from '@containers/Payment/ErrorScreens/BookingWindowCancelled/Loadable';
import BookingWindowClosed from '@containers/Payment/ErrorScreens/BookingWindowClosed/Loadable';
import CancellationWindowClosed from '@containers/Payment/ErrorScreens/CancellationWindowClosed/Loadable';
import RescheduleWindowClosed from '@containers/Payment/ErrorScreens/RescheduleWindowClosed/Loadable';
import SessionAlreadyCompleted from '@containers/Payment/ErrorScreens/SessionAlreadyCompleted/Loadable';
// Scheduling Components
import Scheduling from '@containers/Scheduling/Loadable';
import SlotBookingContainer from '@containers/Scheduling/SlotBooking/Loadable';
import ProposeNewTime from '@containers/Scheduling/ProposeNewTime/Loadable';
import AcceptProposedTimeContainer from '@containers/Scheduling/AcceptProposedTime/Loadable';
// import SchedulingErrorContainer from '@containers/Scheduling/ErrorScreens/Loadable';
import InterviewDecline from '@containers/Scheduling/InterviewDecline/Loadable';
import JoinInterview from '@containers/Scheduling/JoinInterview/Loadable';
import DeclineProposedTimeContainer from '@containers/Scheduling/DeclineProposedTime/Loadable';
// Drive Components
import DriveCancelWindow from '@containers/Drive/DriveCancel/Loadable';
import DriveLayout from '@containers/Drive/Loadable';
import DriveSlotBookingContainer from '@containers/Drive/DriveScheduling/Loadable';
import DriveRegister from '@containers/Drive/Register/Loadable';
import DrivePostRegistrationForm from '@containers/Drive/Register/DrivePostRegistrationForm/Loadable';
import ConsentContainer from '@containers/Consent/Loadable';
// Standalone Components
import Standalone from '@containers/Standalone/Loadable';
import StandaloneInstructions from '@containers/Standalone/StandaloneInstructions/Loadable';
import StandaloneSuccess from '@containers/Standalone/StandaloneSuccess/Loadable';
import StandaloneForm from '@containers/Standalone/StandaloneForm/Loadable';
// Lobby Components
import LobbyWaitingContainer from '@containers/Drive/Looby/LobbyWaiting/Loadable';
const router = createBrowserRouter([
    {
        path: '/',
        element: _jsx(RootLayout, {}),
        errorElement: _jsx(RootErrorPage, {}),
        children: [
            {
                lazy: LoginContainer,
                children: [
                    {
                        path: 'login',
                        lazy: Login,
                    },
                    {
                        path: 'verify',
                        lazy: VerifyOtp,
                    },
                ],
            },
            {
                lazy: AuthRedirectLoadable,
                children: [
                    {
                        path: 'redirect',
                        element: _jsx(Redirect, {}),
                    },
                    {
                        path: 'logout',
                        element: _jsx(Logout, {}),
                    },
                    {
                        path: 'registration-form',
                        lazy: RegistrationForm,
                    },
                    {
                        path: 'standalone',
                        lazy: Standalone,
                        children: [
                            {
                                path: 'form-instruction',
                                lazy: StandaloneInstructions,
                            },
                            {
                                path: 'form',
                                lazy: StandaloneForm,
                            },
                            {
                                path: 'post-form-submission',
                                lazy: StandaloneSuccess,
                            },
                        ],
                    },
                    {
                        path: 'register',
                        lazy: RegisterCandidate,
                    },
                    {
                        path: 'assessment',
                        lazy: Assessment,
                        children: [
                            {
                                path: 'invite',
                                lazy: Invite,
                            },
                            {
                                path: 'assessment-error',
                                lazy: AssessmentError,
                            },
                            {
                                path: 'hardware-check',
                                lazy: HardwareTest,
                            },
                            // {
                            //   path: 'guidelines',
                            //   element: <div>guidelines</div>,
                            // },
                            {
                                path: 'instruction',
                                lazy: Instructions,
                            },
                            {
                                path: 'playground',
                                lazy: PlayGround,
                                children: [
                                    {
                                        path: 'objective',
                                        lazy: ObjectivePlayGround,
                                    },
                                    {
                                        path: 'async',
                                        lazy: AsyncPlayGround,
                                    },
                                ],
                            },
                            {
                                path: 'result',
                                lazy: Submission,
                            },
                            {
                                path: 'submit',
                                lazy: AssessmentSubmission,
                            },
                            {
                                path: 'feedback',
                                lazy: Feedback,
                            },
                            {
                                path: 'thank-you',
                                lazy: Thankyou,
                            },
                        ],
                    },
                    {
                        path: 'payment',
                        lazy: PaymentLayout,
                        children: [
                            {
                                path: 'details',
                                lazy: SKUDetails,
                            },
                            {
                                path: 'summary',
                                lazy: PaymentSummary,
                            },
                        ],
                    },
                    {
                        path: 'drive',
                        lazy: DriveLayout,
                        children: [
                            {
                                path: 'register',
                                lazy: DriveRegister,
                            },
                            {
                                path: 'post-registration-form',
                                lazy: DrivePostRegistrationForm,
                            },
                            {
                                path: 'booking-cancel',
                                lazy: CancelBookingContainer,
                            },
                            // todo: think about toggle cancel and concluded based on status
                            {
                                path: 'cancel',
                                lazy: DriveCancelWindow,
                            },
                            {
                                path: 'booking-window-cancel',
                                lazy: BookingWindowCancelled,
                            },
                            // {
                            //   path: 'concluded',
                            //   lazy: DriveConcluded,
                            // },
                            {
                                path: 'lobby-waiting',
                                lazy: LobbyWaitingContainer,
                            },
                            {
                                path: 'slot-booking',
                                lazy: DriveSlotBookingContainer,
                            },
                        ],
                    },
                    {
                        path: 'consent',
                        lazy: ConsentContainer,
                    },
                    {
                        path: 'scheduling',
                        lazy: Scheduling,
                        children: [
                            {
                                path: 'propose-new-time-accept',
                                lazy: AcceptProposedTimeContainer,
                            },
                            {
                                path: 'propose-new-time',
                                lazy: ProposeNewTime,
                            },
                            {
                                path: 'propose-new-time-decline',
                                lazy: DeclineProposedTimeContainer,
                            },
                            {
                                path: 'drive-registration',
                                lazy: DriveRegister,
                            },
                            {
                                path: 'slot-booking',
                                lazy: SlotBookingContainer,
                            },
                            {
                                path: 'join-interview',
                                lazy: JoinInterview,
                            },
                            // {
                            //   path: 'error',
                            //   lazy: SchedulingErrorContainer,
                            // },
                            {
                                path: 'booking-confirmation',
                                lazy: BookingConfirm,
                            },
                            {
                                path: 'reschedule-booking',
                                lazy: BookingReSchedule,
                            },
                            {
                                path: 'cancellation-closed',
                                lazy: CancellationWindowClosed,
                            },
                            {
                                path: 'reschedule-closed',
                                lazy: RescheduleWindowClosed,
                            },
                            {
                                path: 'booking-closed',
                                lazy: BookingWindowClosed,
                            },
                            {
                                path: 'session-closed',
                                lazy: SessionAlreadyCompleted,
                            },
                            {
                                path: 'decline-interview',
                                lazy: InterviewDecline,
                            },
                            // {
                            //   path: 'booking-cancel',
                            //   lazy: BookingWindowCancelled,
                            // },
                        ],
                    },
                    // {
                    //   path: 'proctoring-scheduling',
                    //   // lazy: The container layout need to be injected here.
                    //   // In the layout container we have to validate which screen the candidate will land
                    //   children: [
                    //     {
                    //       path: '/slot-booking',
                    //     },
                    //     {
                    //       path: '/slot-confirmation',
                    //     },
                    //     {
                    //       path: '/slot-cancellation',
                    //     },
                    //     {
                    //       path: '/slot-cancellation-confirmation',
                    //     },
                    //     {
                    //       path: '/error',
                    //     },
                    //   ],
                    // },
                ],
            },
        ],
    },
]);
export default router;
